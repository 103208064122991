import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.4_react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/lexend.woff2\",\"display\":\"swap\",\"variable\":\"--font-lexend\"}],\"variableName\":\"lexend\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@playwright+test@1.44.1_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/Virgil.woff2\",\"display\":\"swap\",\"variable\":\"--font-virgil\"}],\"variableName\":\"virgil\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/vercel/path0/src/components/Layout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/tailwind.css");
